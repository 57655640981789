<template>
  <div>
    <b-alert
      variant="danger"
      :show="showError"
      :dismissible="false"
    >
      <h4 class="alert-heading">Пользователь не найден</h4>
      <div class="alert-body">
        Пользователь id#{{ $route.params.id }} не найден! Проверьте
        <b-link
          :to="{ name: 'user-list' }"
        >
          список пользователей
        </b-link>
      </div>
    </b-alert>

    <template v-if="userData">
      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col
                cols="12"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <div class="d-flex justify-content-start">
                  <b-avatar
                    size="104px"
                    :text="userData.name"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-2">
                      <h4 class="mb-0">
                        {{ userData.name }}
                        <user-role-badge :roles-list="roles" :user-role="userData.role" class="ml-1"/>
                      </h4>
                      <span class="card-text">{{ userData.email }}</span>
                    </div>
                    <div class="d-flex flex-wrap">
                      <b-button
                        :to="{ name: 'user-edit', params: { id: userData.id } }"
                        variant="primary"
                      >
                        Редактировать
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        class="ml-1"
                      >
                        Удалить
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import { BAlert, BLink, BRow, BCol, BAvatar, BCard, BButton, BBadge } from 'bootstrap-vue'
import { roles } from './usersData'
import UserRoleBadge from './UserRoleBadge.vue'

export default {
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    UserRoleBadge,
  },
  data() {
    return {
      userData: null,
      exists: true,
      showError: false,
      roles,
    }
  },
  mounted() {
    this.fetchUser()
  },
  methods: {
    fetchUser() {
      this.$axios.get(`/users/${this.$route.params.id}`)
        .then(response => {
          this.userData = { ...response.data.data }
        })
        .catch(() => {
          this.userData = null
          this.showError = true
        })
    },
  },
}
</script>
